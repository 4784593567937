@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap");
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

*,
::after,
::before {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #a7a7a7;
}

::-webkit-scrollbar-thumb:hover {
  background: #222222;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

#root {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
}
#root > div:not(.navbar) {
  flex-grow: 1;
  overflow-y: scroll;
}

body {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #000;
  text-align: left;
  background-color: #fff;
  overflow-x: hidden;
}
body *[pointer=true] {
  cursor: pointer;
}
body *[pointer=true] > * {
  cursor: pointer;
}
body *[pointer=denied] {
  cursor: no-drop;
}
body *[pointer=denied] * {
  cursor: no-drop !important;
}
body *[pointer=arrow] {
  cursor: default;
}
body *[pointer=eventnone] {
  pointer-events: none;
  background: #f8f8f8 !important;
  border: 1px solid #d4d4d4 !important;
}
body select {
  background: url("../img/icons/arrow_down.png") no-repeat 95% 50%;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
}
body select:disabled {
  background-color: #f8f8f8;
}
body select:focus-visible {
  outline: 0;
}
body input,
body textarea,
body select {
  font-family: "Sen", sans-serif;
  border: 1px solid #7d7d7d;
}
body input:-moz-read-only, body textarea:-moz-read-only, body select:-moz-read-only {
  cursor: no-drop;
  background: #ede8e8;
}
body input:read-only,
body textarea:read-only,
body select:read-only {
  cursor: no-drop;
  background: #ede8e8;
}
body .hidden {
  display: none !important;
}
body .loader {
  font-size: 30px;
  text-align: center;
}
body .error {
  font-family: "Sen", sans-serif;
  font-size: 13px;
  line-height: 16px;
  color: #ff0000;
  margin: 0 !important;
}
body *[type=hidden] {
  visibility: hidden;
}

a {
  all: unset;
}

.text-align-center {
  text-align: center !important;
}

.justify-content-center > div {
  justify-content: center !important;
}

.c-red {
  color: #e31e24;
}

.c-grey {
  color: #222222;
}

.action_btn {
  background: #222222;
  border-radius: 2px;
  padding: 8px;
  color: #fff;
}
.action_btn.ml {
  margin-left: 11px;
}
.action_btn.disabled {
  background: rgba(0, 0, 0, 0.2);
  color: #000;
}
.action_btn.delete {
  padding-block: 9px;
  padding-inline: 20px;
  font-size: 18px;
  line-height: 21px;
  margin-inline: 20px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #e31e24;
  border-radius: 2px;
}

*[border-red=true] {
  border: 1px solid #ff0000 !important;
}

.f-sen {
  font-family: "Sen", sans-serif !important;
}

.f-rale {
  font-family: "Raleway", sans-serif !important;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
.login {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: url("../img/loginbanner.png");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login .main_txt {
  margin-right: 60px;
  text-align: center;
  font-size: 33px;
  line-height: 40px;
}
.login .main_txt > div {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}
.login .main_txt .first_line {
  position: relative;
}
.login .main_txt .first_line > img {
  position: absolute;
  bottom: 60%;
  right: 100%;
}
.login .login_form {
  padding: 34px 58px;
  background: #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: #fff;
  width: 489px;
}
.login .login_form .login_form_heading {
  font-size: 28px;
  line-height: 30px;
  margin-bottom: 61px;
  text-align: center;
}
.login .login_form label {
  display: block;
  font-size: 23px;
  line-height: 28px;
  margin-bottom: 5px;
}
.login .login_form input {
  margin-bottom: 4px;
  width: 100%;
  height: 55px;
  border: 0;
  border-radius: 2px;
}
.login .login_form input:focus-visible {
  border: 1px solid #05c3ff;
  outline: 0;
}
.login .login_form .input {
  margin-bottom: 12px;
  position: relative;
}
.login .login_form .input .err_img {
  display: none;
}
.login .login_form .input.error .err_img {
  position: absolute;
  display: block;
  top: 15px;
  right: -31px;
}
.login .login_form .input.error input {
  border: 1px solid red;
}
.login .login_form .log_in {
  cursor: pointer;
  margin-top: 38px;
  margin-bottom: 13px;
  font-weight: 600;
  border-radius: 2px;
  background: #ede8e8;
  border: 1px solid #000000;
  text-align: center;
  color: #000;
  opacity: 0.6;
  padding-block: 16px;
  font-size: 23px;
  line-height: 28px;
  cursor: no-drop;
  color: #fff;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #e31e24;
}
.login .login_form .log_in.active {
  pointer-events: unset;
  cursor: pointer;
  opacity: 1;
}

.navbar {
  display: flex;
  padding: 32px 36px;
  align-items: center;
  gap: 30px;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 10;
}
.navbar img {
  margin-right: 30px;
}
.navbar .welcome {
  font-size: 30px;
  line-height: 35px;
  margin-right: auto;
}
.navbar .nav_actions {
  display: flex;
  align-items: center;
  gap: 30px;
  font-size: 21px;
  line-height: 22px;
  font-weight: 600;
  color: #e31e24;
}
.navbar .nav_actions > div {
  cursor: pointer;
  transition: all 300ms linear;
  border-radius: 3px;
  padding: 10px;
}
.navbar .nav_actions > div.btn.active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #e31e24;
  color: #fff;
}

.settings {
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
}
.settings .employee_table {
  padding-inline: 92px;
  flex-grow: 1;
  overflow-y: scroll;
}
.settings .setings_filter {
  padding-inline: 92px;
}
.settings .settings_table {
  padding-inline: 92px;
  flex-grow: 1;
  overflow-y: scroll;
}
.settings .settings_table .btn_normal {
  font-size: inherit;
  line-height: 1;
}
.settings .settings_table .row > div:not(:last-child) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px 0 0;
}
.settings .settingsmainfilter {
  display: flex;
  flex: 0 0 75px;
  background: #ede8e8;
}
.settings .settingsmainfilter .filter_item {
  flex-grow: 1;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 7px 0;
}
.settings .settingsmainfilter .filter_item:not(:first-child) {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}
.settings .settingsmainfilter .filter_item.active {
  margin: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #e31e24;
  color: #fff;
}
.settings .settingsmainfilter .filter_item.active + div {
  border-left: 1px solid transparent;
}
.settings .delete_modal {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(125, 125, 125, 0.1215686275);
  z-index: 100;
}
.settings .delete_modal > div {
  width: 488px;
  background: #fff;
  padding: 26px 47px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-size: 27px;
  line-height: 32px;
  text-align: center;
}
.settings .delete_modal > div .txt {
  color: #e31e24;
  margin-bottom: 40px;
  font-size: 30px;
  line-height: 35px;
}
.settings .delete_modal > div .txt2 {
  font-size: 18px;
  line-height: 21px;
  margin: 33px auto 47px;
}
.settings .delete_modal > div .btn_group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.account_page {
  height: 100vh;
  padding-inline: 36px;
}
.account_page .accounttablefilter {
  padding-inline: 50px;
}
.account_page .accounttablefilter .table_main_filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.account_page .accounttablefilter .table_main_filter > .item {
  cursor: pointer;
  color: #7d7d7d;
  font-size: 22px;
  line-height: 23px;
  font-weight: 600;
  padding: 37px 10px;
  position: relative;
}
.account_page .accounttablefilter .table_main_filter > .item::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #000;
  left: 0;
  bottom: 20px;
  transform: scale(0);
  transform-origin: center;
  transition: transform 300ms linear;
}
.account_page .accounttablefilter .table_main_filter > .item.active {
  color: #222222;
}
.account_page .accounttablefilter .table_main_filter > .item.active::after {
  transform: scale(1);
}
.account_page .accounttablefilter .table_submain_filter {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 20px;
  line-height: 23px;
  height: 39px;
}
.account_page .accounttablefilter .table_submain_filter > div.cust_search, .account_page .accounttablefilter .table_submain_filter > div.cust_dropdown, .account_page .accounttablefilter .table_submain_filter > div.date_picker {
  height: 39px;
  width: 20%;
}
.account_page .accounttablefilter .table_submain_filter > div.cust_search {
  flex-grow: 1;
}
.account_page .accounttablefilter .table_submain_filter > div:first-child, .account_page .accounttablefilter .table_submain_filter > div:last-child {
  white-space: nowrap;
}
.account_page .accounttablefilter .table_submain_filter > div.btn_normal {
  height: 100%;
}
.account_page .addinvoice,
.account_page .editinvoice {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(125, 125, 125, 0.1215686275);
  z-index: 100;
}
.account_page .addinvoice .editinvoice_btngroup,
.account_page .editinvoice .editinvoice_btngroup {
  display: flex;
  gap: 10px;
}
.account_page .addinvoice .editinvoice_btngroup > .delete_btn,
.account_page .editinvoice .editinvoice_btngroup > .delete_btn {
  background: #e31e24;
}
.account_page .addinvoice > div,
.account_page .editinvoice > div {
  background: #fff;
  color: #000;
  padding: 26px 47px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  max-height: calc(100vh - 20px);
  max-width: calc(100vw - 20px);
  overflow-y: scroll;
}
.account_page .addinvoice > div .header,
.account_page .editinvoice > div .header {
  font-size: 31px;
  line-height: 37px;
  font-weight: 500;
  color: #e31e24;
}
.account_page .addinvoice > div .body,
.account_page .editinvoice > div .body {
  margin-block: 50px;
}
.account_page .addinvoice > div .body > div,
.account_page .editinvoice > div .body > div {
  margin-bottom: 25px;
}
.account_page .addinvoice > div .body .cust_input .title,
.account_page .editinvoice > div .body .cust_input .title {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 8px;
}
.account_page .addinvoice > div .body .cust_input input,
.account_page .editinvoice > div .body .cust_input input {
  width: 100%;
  font-size: 16px;
  line-height: 19px;
  outline: 0;
  padding: 6px 12px;
}
.account_page .addinvoice > div .body .cust_attachment,
.account_page .editinvoice > div .body .cust_attachment {
  margin-top: 76px;
}
.account_page .addinvoice > div .body .cust_attachment .title label,
.account_page .editinvoice > div .body .cust_attachment .title label {
  display: flex;
  align-items: center;
  gap: 10px;
}
.account_page .addinvoice > div .body .cust_attachment .title label input,
.account_page .editinvoice > div .body .cust_attachment .title label input {
  display: none;
}
.account_page .addinvoice > div .body .cust_attachment .filename,
.account_page .editinvoice > div .body .cust_attachment .filename {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-family: "Sen", sans-serif !important;
  display: flex;
  align-items: center;
  gap: 10px;
}
.account_page .addinvoice > div .body .cust_attachment .filename img,
.account_page .editinvoice > div .body .cust_attachment .filename img {
  width: 20px;
}
.account_page .addinvoice > div .body .cust_attachment .filename .action_btn,
.account_page .editinvoice > div .body .cust_attachment .filename .action_btn {
  margin-block: 2px;
}
.account_page .addinvoice > div .body .input_group,
.account_page .editinvoice > div .body .input_group {
  display: flex;
  gap: 30px;
}
.account_page .addinvoice > div .body .input_group > div,
.account_page .editinvoice > div .body .input_group > div {
  width: 50%;
}
.account_page .addinvoice > div .body .date_picker,
.account_page .addinvoice > div .body .half_inp,
.account_page .editinvoice > div .body .date_picker,
.account_page .editinvoice > div .body .half_inp {
  width: calc(50% - 15px);
  z-index: 100;
}
.account_page .addinvoice > div .body textarea,
.account_page .editinvoice > div .body textarea {
  resize: none;
  width: 100%;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 19px;
}
.account_page .addinvoice > div .body .option_group,
.account_page .editinvoice > div .body .option_group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 10px;
}
.account_page .addinvoice > div .body .option_group .option_item,
.account_page .editinvoice > div .body .option_group .option_item {
  width: calc(33% - 6.6666666667px);
}
.account_page .addinvoice > div .body .option_group .title,
.account_page .editinvoice > div .body .option_group .title {
  font-weight: 700;
  color: #7d7d7d;
  margin-bottom: 5px;
}
.account_page .addinvoice > div .body .option_group .option_case_group,
.account_page .editinvoice > div .body .option_group .option_case_group {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
  font-weight: 600;
  padding: 2px;
}
.account_page .addinvoice > div .body .option_group .option_case_group > div,
.account_page .editinvoice > div .body .option_group .option_case_group > div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 10px;
  position: relative;
}
.account_page .addinvoice > div .body .option_group .option_case_group > div.active,
.account_page .editinvoice > div .body .option_group .option_case_group > div.active {
  background: #ffffff;
  border-radius: 5px;
  z-index: 10;
  color: #e31e24;
}
.account_page .addinvoice > div .footer,
.account_page .editinvoice > div .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.account_page .delete_modal {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(125, 125, 125, 0.1215686275) !important;
  z-index: 100;
  color: #fff;
}
.account_page .delete_modal > div {
  width: 488px;
  background: #fff;
  padding: 26px 47px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-size: 27px;
  line-height: 32px;
  text-align: center;
}
.account_page .delete_modal > div .txt {
  white-space: normal;
  color: #e31e24;
  margin-bottom: 40px;
  font-size: 30px;
  line-height: 35px;
}
.account_page .delete_modal > div .txt2 {
  font-size: 18px;
  line-height: 21px;
  margin: 33px auto 47px;
  color: #000;
}
.account_page .delete_modal > div .btn_group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.account_page .delete_modal > div .btn_group > div {
  cursor: pointer;
}

.companyyearselector {
  margin-top: 17px;
  background: #b6181d;
  border-radius: 25px;
  color: #fff;
  padding-block: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 20px;
  gap: 20px;
  font-size: 28px;
  line-height: 30px;
  z-index: 100;
}
.companyyearselector > div {
  z-index: 100;
  width: 30%;
}
.companyyearselector .choosecompany {
  width: 40%;
  text-align: right;
}
.companyyearselector .cust_dropdown {
  border-radius: 10px;
  font-size: 20px;
  line-height: 23px;
  font-weight: 500;
  color: #000;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-family: "Sen", sans-serif !important;
}
.companyyearselector .cust_dropdown > .dropdown_txt {
  border-radius: 10px;
  padding: 10px;
}
.companyyearselector .cust_dropdown > .dropdown_txt:hover {
  background: #fff;
}
.companyyearselector .cust_dropdown .selection_group {
  border-top: 1px solid transparent;
}
.companyyearselector .cust_dropdown .selection_group .item:not(:first-child):not(.active) {
  border-top: 1px solid rgba(0, 0, 0, 0.2509803922);
}
.companyyearselector .cust_dropdown .selection_group .item.active + .item {
  border-top: 1px solid transparent;
}

.setings_filter {
  margin-top: 30px;
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 28px;
  gap: 25px;
  height: 1005;
  position: -webkit-sticky;
  position: sticky;
  z-index: 100;
  white-space: nowrap;
}
.setings_filter .btn {
  font-size: 18px;
  line-height: 21px;
  padding: 8px;
  color: #fff;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}
.setings_filter > .filter_group {
  width: 100%;
  display: flex;
  gap: 25px;
}
.setings_filter > .filter_group > div {
  width: 70%;
}
.setings_filter > .filter_group > div:last-child {
  width: 30%;
}
.setings_filter[type=company] .filter_group {
  width: 30%;
  margin: auto;
}
.setings_filter[type=company] .filter_group div {
  width: 100%;
}
.setings_filter[type=branch] .filter_group {
  width: 30%;
  margin: auto;
}
.setings_filter[type=branch] .filter_group div {
  width: 100%;
}

.cust_dropdown {
  background: #fff;
  border: 1px solid #7d7d7d;
  display: flex;
  align-items: center;
  height: 35px;
  width: 100%;
  font-size: 15px;
  line-height: 15px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.cust_dropdown[read_only=true] {
  pointer-events: none;
  background: #ede8e8;
}
.cust_dropdown[read_only=true] .selection_group {
  display: none !important;
}
.cust_dropdown:after {
  content: "";
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  margin: auto 0;
  --dim: 6px;
  height: var(--dim);
  width: var(--dim);
  background: #7d7d7d;
  transform: rotateZ(45deg);
  -webkit-clip-path: polygon(100% 0, 0% 100%, 100% 100%);
          clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}
.cust_dropdown.active {
  overflow: unset;
}
.cust_dropdown.active:after {
  transform: rotateZ(-135deg);
}
.cust_dropdown[error=true] {
  border: 1px solid #ff0000;
}
.cust_dropdown .dropdown_txt {
  width: 100%;
  height: 100%;
  padding-inline: 10px;
  display: flex;
  align-items: center;
}
.cust_dropdown .dropdown_txt:hover {
  background: #e7ebf0;
}
.cust_dropdown .selection_group {
  z-index: 100;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}
.cust_dropdown .selection_group > div {
  margin: 20px 0 0 0;
  background: #fff;
  border: 1px solid #e5e5e5;
  padding: 5px;
}
.cust_dropdown .selection_group > div > div {
  padding: 10px;
}
.cust_dropdown .selection_group > div > div.active {
  background: #daecff;
}
.cust_dropdown .selection_group > div > div:hover {
  background: #e7ebf0;
}
.cust_dropdown .selection_group.active {
  top: unset;
  bottom: 100%;
}
.cust_dropdown .selection_group.active > div {
  margin: 0 0 20px 0;
}

.cust_table .table_header {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background: #fff;
  color: #7d7d7d;
  border-bottom: 1px solid #e5e5e5;
  box-shadow: 100px 0px 0px white;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding-block: 16px;
  z-index: 1;
}
.cust_table .table_header > div {
  width: 20%;
}
.cust_table .table_body .row {
  display: flex;
  align-items: center;
  font-size: 17px;
  line-height: 20px;
}
.cust_table .table_body .row > .btn_normal {
  font-size: 17px !important;
  line-height: 20px !important;
}
.cust_table.settings_table .table_header, .cust_table.invoice_table .table_header {
  padding-top: 40px;
}
.cust_table.settings_table .table_header .has_arrows, .cust_table.invoice_table .table_header .has_arrows {
  cursor: pointer;
  display: flex;
  gap: 10px;
}
.cust_table.settings_table .table_header .has_arrows .arrow_group, .cust_table.invoice_table .table_header .has_arrows .arrow_group {
  z-index: -1;
}
.cust_table.settings_table .table_header .has_arrows .arrow_group img, .cust_table.invoice_table .table_header .has_arrows .arrow_group img {
  height: 12px;
  filter: invert(56%) sepia(0%) saturate(461%) hue-rotate(226deg) brightness(92%) contrast(88%);
}
.cust_table.settings_table .table_header .has_arrows .arrow_group img.active, .cust_table.invoice_table .table_header .has_arrows .arrow_group img.active {
  filter: invert(13%) sepia(78%) saturate(4191%) hue-rotate(348deg) brightness(93%) contrast(95%);
}
.cust_table.settings_table .table_header > div:nth-child(1), .cust_table.invoice_table .table_header > div:nth-child(1) {
  width: 20%;
}
.cust_table.settings_table .table_header > div:nth-child(2), .cust_table.invoice_table .table_header > div:nth-child(2) {
  width: 30%;
}
.cust_table.settings_table .table_header > div:nth-child(3), .cust_table.invoice_table .table_header > div:nth-child(3) {
  width: 20%;
}
.cust_table.settings_table .table_header > div:nth-child(4), .cust_table.invoice_table .table_header > div:nth-child(4) {
  width: 20%;
}
.cust_table.settings_table .table_header > div:nth-child(5), .cust_table.invoice_table .table_header > div:nth-child(5) {
  width: 10%;
}
.cust_table.settings_table .table_body .row, .cust_table.invoice_table .table_body .row {
  padding: 20px 0;
}
.cust_table.settings_table .table_body .row[deleted=true], .cust_table.invoice_table .table_body .row[deleted=true] {
  background: rgba(255, 0, 0, 0.2);
}
.cust_table.settings_table .table_body .row > div:nth-child(1), .cust_table.invoice_table .table_body .row > div:nth-child(1) {
  width: 20%;
}
.cust_table.settings_table .table_body .row > div:nth-child(2), .cust_table.invoice_table .table_body .row > div:nth-child(2) {
  width: 30%;
}
.cust_table.settings_table .table_body .row > div:nth-child(3), .cust_table.invoice_table .table_body .row > div:nth-child(3) {
  width: 20%;
}
.cust_table.settings_table .table_body .row > div:nth-child(4), .cust_table.invoice_table .table_body .row > div:nth-child(4) {
  width: 20%;
}
.cust_table.settings_table .table_body .row > div:nth-child(5), .cust_table.invoice_table .table_body .row > div:nth-child(5) {
  width: 10%;
}
.cust_table.settings_table .table_body .row .employee_menu, .cust_table.invoice_table .table_body .row .employee_menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cust_table.settings_table .table_body .row .employee_menu img, .cust_table.invoice_table .table_body .row .employee_menu img {
  cursor: pointer;
}
.cust_table.settings_table .table_body .row .employee_menu .menu_main, .cust_table.invoice_table .table_body .row .employee_menu .menu_main {
  position: absolute;
  top: 0;
  right: 100%;
  background: #fff;
  border: 1px solid transparent;
  height: 100px;
  width: 150px;
  border-radius: 10px;
  transform: scale(0);
  transform-origin: right top;
  transition: transform 0.3s linear;
  padding: 10px 15px;
  border: 1px solid #000;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.cust_table.settings_table .table_body .row .employee_menu .menu_main > div, .cust_table.invoice_table .table_body .row .employee_menu .menu_main > div {
  cursor: pointer;
  font-size: 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.cust_table.settings_table .table_body .row .employee_menu .menu_main.active, .cust_table.invoice_table .table_body .row .employee_menu .menu_main.active {
  transform: scale(1);
}
.cust_table.settings_table.company_table .table_header > div,
.cust_table.settings_table.company_table .table_body .row > div {
  width: 90%;
}
.cust_table.settings_table.company_table .table_header > div:last-child,
.cust_table.settings_table.company_table .table_body .row > div:last-child {
  width: 10%;
}
.cust_table.settings_table.branch_table .table_header > div,
.cust_table.settings_table.branch_table .table_body .row > div {
  width: 30%;
}
.cust_table.settings_table.branch_table .table_header > div:nth-child(2),
.cust_table.settings_table.branch_table .table_body .row > div:nth-child(2) {
  text-align: center;
}
.cust_table.settings_table.branch_table .table_header > div:nth-child(3) .has_arrows,
.cust_table.settings_table.branch_table .table_body .row > div:nth-child(3) .has_arrows {
  justify-content: center;
}
.cust_table.settings_table.branch_table .table_header > div:last-child,
.cust_table.settings_table.branch_table .table_body .row > div:last-child {
  width: 10%;
}
.cust_table.invoice_table .table_header,
.cust_table.invoice_table .table_body .row {
  padding-inline: 50px;
}
.cust_table.invoice_table .table_header > div:nth-child(1),
.cust_table.invoice_table .table_body .row > div:nth-child(1) {
  width: 15%;
}
.cust_table.invoice_table .table_header > div:nth-child(2),
.cust_table.invoice_table .table_body .row > div:nth-child(2) {
  width: 15%;
}
.cust_table.invoice_table .table_header > div:nth-child(3),
.cust_table.invoice_table .table_body .row > div:nth-child(3) {
  width: 15%;
}
.cust_table.invoice_table .table_header > div:nth-child(4),
.cust_table.invoice_table .table_body .row > div:nth-child(4) {
  width: 25%;
}
.cust_table.invoice_table .table_header > div:nth-child(5),
.cust_table.invoice_table .table_body .row > div:nth-child(5) {
  width: 15%;
}
.cust_table.invoice_table .table_header > div:nth-child(6),
.cust_table.invoice_table .table_body .row > div:nth-child(6) {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cust_table.invoice_table.employee_table .table_header,
.cust_table.invoice_table.employee_table .table_body .row {
  padding-inline: 50px;
}
.cust_table.invoice_table.employee_table .table_header > div:nth-child(1),
.cust_table.invoice_table.employee_table .table_body .row > div:nth-child(1) {
  width: 25%;
}
.cust_table.invoice_table.employee_table .table_header > div:nth-child(2),
.cust_table.invoice_table.employee_table .table_body .row > div:nth-child(2) {
  width: 20%;
}
.cust_table.invoice_table.employee_table .table_header > div:nth-child(3),
.cust_table.invoice_table.employee_table .table_body .row > div:nth-child(3) {
  width: 40%;
}
.cust_table.invoice_table.employee_table .table_header > div:nth-child(4),
.cust_table.invoice_table.employee_table .table_body .row > div:nth-child(4) {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cust_table.invoice_table .table_body .row > div {
  padding: 0 10px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date_picker {
  position: relative;
  border: 1px solid #7d7d7d;
  border-radius: 2px;
  z-index: 10;
  font-size: 15px;
  cursor: pointer;
}
.date_picker[readonly=true] {
  pointer-events: none;
  background: #ede8e8;
}
.date_picker[readonly=true] .date_txt,
.date_picker[readonly=true] .cust_calender_main {
  display: none;
}
.date_picker .date_txt {
  background: #fff;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 15px;
  position: absolute;
  top: -10px;
  padding: 0 5px;
  left: 10px;
}
.date_picker .cust_calender_main {
  z-index: 1000;
  border: 1px solid rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 5px;
}
.date_picker .cust_calender_main .btn_group {
  display: flex;
  color: rgb(61, 145, 255);
  justify-content: space-around;
  padding: 0 0 10px 0;
}
.date_picker .cust_calender_main .btn_group div {
  cursor: pointer;
}
.date_picker .main_txt {
  padding: 8px;
}
.date_picker .date_icon {
  position: absolute;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  top: 0px;
  bottom: 0px;
  right: 10px;
  margin: auto 0;
}

.adduser {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(125, 125, 125, 0.1215686275);
  z-index: 100;
  font-size: 18px;
  line-height: 21px;
}
.adduser > div {
  width: 488px;
  background: #fff;
  padding: 26px 47px;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.adduser > div .header {
  margin-bottom: 57px;
  font-size: 27px;
  line-height: 32px;
  text-align: center;
}
.adduser > div .body .input {
  margin-bottom: 25px;
}
.adduser > div .body .input_txt {
  margin-bottom: 8px;
}
.adduser > div .body input {
  width: 100%;
  height: 31px;
  border: 1px solid #000000;
  border-radius: 2px;
  padding-inline: 8px;
  outline: 0;
}
.adduser > div .body textarea {
  width: 100%;
  resize: none;
  padding: 8px;
  outline: 0;
}
.adduser > div .body .input_group {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 30px;
       column-gap: 30px;
}
.adduser > div .body .input_group > div {
  width: calc(50% - 15px);
}
.adduser > div .body .section_select .section_select_group {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
}
.adduser > div .body .section_select .section_select_group > div {
  cursor: pointer;
  width: 33%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
}
.adduser > div .body .section_select .section_select_group > div input {
  height: 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.adduser > div .footer {
  margin: 54px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.adduser > div .footer > .btn {
  cursor: pointer;
}
.adduser > div .footer .btn_group {
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.adduser > div .footer .btn_group .delete {
  background: #e31e24;
}

.cust_search {
  height: 35px;
  border: 1px solid #7d7d7d;
  display: flex;
  align-items: center;
}
.cust_search img {
  margin: 0 10px;
}
.cust_search input {
  width: 100%;
  height: 100%;
  outline: 0;
  border: 0;
}

.btn_normal {
  font-size: 18px;
  line-height: 21px;
  padding: 8px 30px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: #000;
  cursor: pointer;
}
.btn_normal.active {
  background: #7d7d7d;
}

.center {
  text-align: center;
}